import axios from "axios"
import asyncWait from "@/helpers/async-wait"

const axiosInstance = axios.create({
  timeout: 10000,
  retry: 3,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  async (err) => {
    const { config } = err

    if (
      !config ||
      !config.retry ||
      (err.response?.status >= 400 && err.response?.status < 500)
    ) {
      return Promise.reject(err)
    }

    config.retry -= 1

    await asyncWait(500)
    return await axiosInstance(config)
  },
)

export { axiosInstance }
