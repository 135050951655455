import axios from "axios"
import { appVersion } from "@/helpers/env"

const sessionStart = Date.now()

document.addEventListener("visibilitychange", async () => {
  if (document.hidden) {
    return
  }

  // If more than 1h has passed since the session start, check for updates
  if (Date.now() - sessionStart < 3600000) {
    return
  }

  try {
    const { data } = await axios.get("/api/v1/app-version")

    if (data.appVersion === appVersion) {
      return
    }

    window.location.reload()
  } catch (err) {
    console.error(err)
  }
})
