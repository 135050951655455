import { registerStore } from "@/scripts/alpine"
import { axiosInstance } from "@/helpers/client"

registerStore("user", {
  isLoading: true,
  isLoggedIn: false,
  tier: "guest",
  status: "active",
  prepaidUsage: 0,
  prepaidLimit: 0,
  monthlyUsage: 0,
  monthlyUsageResetDate: 0,
  customClaims: {},

  init() {
    const user = window.user

    if (user) {
      this.isLoggedIn = true
      this.tier = user.tier
      this.status = user.status
      this.prepaidUsage = user.prepaidUsage
      this.prepaidLimit = user.prepaidLimit
      this.monthlyUsage = user.monthlyUsage
      this.monthlyUsageResetDate = user.monthlyUsageResetDate
      this.customClaims = user.customClaims

      this.updateUser()
    }
  },

  async updateUser() {
    this.isLoading = true

    try {
      if (window.location.pathname === "/dashboard/logout") {
        throw new Error("Logging out")
      }

      const user = await axiosInstance
        .post("/api/v2/user", {})
        .then((res) => res.data)

      this.isLoggedIn = !!user?.id
      this.tier = user?.tier
      this.status = user?.status
      this.customClaims = user?.customClaims
    } catch (error) {
      this.isLoggedIn = false
      this.tier = "guest"
      this.status = "active"
      this.customClaims = {}
      this.isLoading = false
    }

    this.isLoading = false
  },
})
